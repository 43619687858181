<template>
  <div>
    <page-header>
      <template v-slot:right>
        <el-button type="primary" @click="handleEdit()">添加员工</el-button>
        <el-button plain @click="handleOpenDealer()">开通经销商</el-button>
      </template>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <div class="condition">
          <el-tabs v-model="searchForm.dataType" @tab-click="handleTabsClick">
            <el-tab-pane label="员工" name="2"></el-tab-pane>
            <el-tab-pane label="经销商" name="1"></el-tab-pane>
          </el-tabs>
          <div class="right-tools">
            <el-input v-model="searchForm.text" placeholder="用户名/姓名/手机号" clearable>
              <el-button slot="append" icon="el-icon-search" @click="handleCurrentChange(1)"></el-button>
            </el-input>
            <dict-select v-model="searchForm.status" dict-name="amsUserStatus" class="right-tools-item" placeholder="状态"
                         @change="handleCurrentChange(1)"></dict-select>
          </div>
        </div>
        <el-row>
          <el-col :span="24">
            <el-table
                :data="rows"
                v-loading="loading"
                style="width: 100%">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column type="index" width="50"></el-table-column>
              <el-table-column prop="username" label="用户名"></el-table-column>
              <el-table-column prop="displayName" label="姓名"></el-table-column>
              <el-table-column prop="mobile" label="手机号" min-width="100"></el-table-column>
              <el-table-column prop="amsDeptName" label="部门" width="200"></el-table-column>
              <el-table-column prop="balance" label="余额" v-if="searchForm.dataType === '1'"></el-table-column>
              <el-table-column prop="discount" label="折扣" v-if="searchForm.dataType === '1'"></el-table-column>
              <el-table-column prop="freeVipCardCount" label="VIP年卡(系统赠送)" v-if="searchForm.dataType === '1'" min-width="120"></el-table-column>
              <el-table-column prop="payVipCardCount" label="VIP年卡(付费购买)" v-if="searchForm.dataType === '1'" min-width="120"></el-table-column>
              <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                  <dict-text :code="scope.row.status" dict-name="amsUserStatus"></dict-text>
                </template>
              </el-table-column>
              <el-table-column prop="creatorName" label="创建人"></el-table-column>
              <el-table-column prop="creationTime" label="创建时间" width="140px"></el-table-column>
              <el-table-column
                  label="操作"
                  width="120">
                <template slot-scope="scope">
                  <el-dropdown>
                <span class="el-dropdown-link">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :disabled="scope.row.userType === 1"
                                        @click.native="handleRemove([scope.row.id])">删除
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="handleEdit(scope.row.id)">编辑
                      </el-dropdown-item>
                      <el-dropdown-item :disabled="scope.row.userType === 1" @click.native="handleEnable(scope.row.id)">
                        启用
                      </el-dropdown-item>
                      <el-dropdown-item :disabled="scope.row.userType === 1"
                                        @click.native="handleDisable(scope.row.id)">禁用
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="handleOpenRoleDialog(scope.row.id)">设置角色
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="handleResetPassword(scope.row.id)">重置密码
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10,20,50,100]"
                :page-size="size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>

        <el-dialog
            title="用户编辑"
            :visible.sync="dialogVisible"
            width="40%">
          <el-form :model="editForm" :rules="rules" ref="editForm" label-width="120px">
            <el-form-item label="部门" prop="amsDeptId">
              <el-select v-model="editForm.amsDeptId">
                <el-option v-for="item in deptList" :key="item.id" :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户名" prop="username">
              <el-input v-model="editForm.username" :disabled="!!currentId" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" v-if="!currentId">
              <el-input v-model="editForm.password" type="password" autocomplete="new-password"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="editForm.mobile" autocomplete="on"></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="displayName">
              <el-input v-model="editForm.displayName"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="idCard">
              <el-input v-model="editForm.idCard"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="editForm.email" class="hy-input"></el-input>
            </el-form-item>
            <el-form-item label="角色" prop="roleIds">
              <el-checkbox-group v-model="editForm.roleIds">
                <el-checkbox v-for="item in roles" :label="item.id" name="roleIds" :key="item.id">
                  {{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button plain @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="handleSave" :loading="editLoading">保存</el-button>
          </div>
        </el-dialog>
        <el-dialog
            title="设置角色"
            :visible.sync="roleDialogVisible"
            width="40%">
          <el-form :model="editForm" :rules="rules" ref="editForm" label-width="80px">
            <el-form-item label="用户名">
              {{ editForm.username }}
            </el-form-item>

            <el-form-item label="角色" prop="roleIds">
              <el-checkbox-group v-model="editForm.roleIds">
                <el-checkbox v-for="item in roles" :label="item.id" name="roleIds" :key="item.id">
                  {{ item.name }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button plain @click="roleDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="handleSaveUserRole" :loading="editRoleLoading">保存</el-button>
          </div>
        </el-dialog>

        <el-dialog
            title="开通经销商"
            :visible.sync="dealerDialogShow"
            width="40%">
          <dealer-create ref="dealer" :roles="roles" :current-account="currentAccount"
                         :dept-list="deptList" @success="handleDealerCreateSuccess"></dealer-create>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
import { AccountApi, SysDeptApi, SysRolesApi, SysUsersApi } from '@/api'
import Dictionary from '@/utils/dictionary'
import DealerCreate from './components/DealerCreate'

const defaultForm = () => {
  return {
    id: '',
    username: '',
    password: '',
    displayName: '',
    mobile: '',
    email: '',
    amsDeptId: '',
    userType: Dictionary.amsUserType.employee.code,
    roleIds: []
  }
}

export default {
  name: 'User',
  components: {
    DealerCreate
  },
  data () {
    const checkPwd = (rule, value, callback) => {
      if (!value && !this.currentId) {
        return callback(new Error('密码不能为空'))
      }
      callback()
    }
    return {
      searchForm: {
        text: '',
        status: '',
        dataType: '2'
      },
      editForm: defaultForm(),
      rules: {
        username: [
          {
            required: true,
            validator: function (rule, value, callback) {
              if (/^[a-zA-Z0-9]{1,16}$/.test(value) === false) {
                callback(new Error('用户名只能包含数字和字母,不能超过16位,不能包含特殊字符、中文'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            validator: checkPwd,
            trigger: 'blur'
          }
        ],
        amsDeptId: [
          {
            required: true,
            message: '请选择部门',
            trigger: 'change'
          }
        ],
        displayName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        idCard: [
          {
            required: true,
            message: '请输入身份证号',
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        roleIds: [
          {
            type: 'array',
            required: true,
            message: '请至少选择一个角色',
            trigger: 'change'
          }
        ]
      },
      dialogVisible: false,
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      currentId: '',
      editLoading: false,
      roles: [],
      deptList: [],
      roleDialogVisible: false,
      editRoleLoading: false,
      loading: false,
      selectRows: [],
      currentAccount: {},
      dealerDialogShow: false
    }
  },
  methods: {
    initData () {
      this.handleSearch()
      SysRolesApi.getAll().then(data => {
        this.roles = data
      })
      SysDeptApi.getAll().then(res => {
        this.deptList = res
      })
      AccountApi.getAccount().then(res => {
        this.currentAccount = res
      })
    },
    handleSearch () {
      this.loading = true
      const params = {
        page: this.page - 1,
        size: this.size
      }
      if (this.searchForm.text) {
        params.text = this.searchForm.text
      }
      if (this.searchForm.status) {
        params.status = this.searchForm.status
      }
      if (this.searchForm.dataType) {
        params.dataType = this.searchForm.dataType
      }
      SysUsersApi.getUserList(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleEdit (id) {
      this.currentId = id
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.editForm.resetFields()
        if (id) {
          SysUsersApi.getUser(id).then(data => {
            Object.assign(this.editForm, data)
          })
        } else {
          this.editForm = defaultForm()
        }
      })
    },
    handleSave () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.editLoading = true
          SysUsersApi.saveUser(this.editForm).then(() => {
            this.editLoading = false
            this.dialogVisible = false
            this.$message.success('保存成功')
            this.handleCurrentChange(1)
          }).catch(() => {
            this.editLoading = false
          })
        } else {
          return false
        }
      })
    },
    handleRemove (id) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      }).then(() => {
        SysUsersApi.remove(id).then(() => {
          this.$message.success('删除成功')
          this.handleCurrentChange(1)
        })
      })
    },
    handleResetPassword (id) {
      this.$confirm('确定要重置用户密码吗？', '提示', {
        type: 'warning'
      }).then(() => {
        SysUsersApi.resetPassword(id).then((result) => {
          this.$alert(`密码重置成功，新密码为：${result}`)
        })
      })
    },
    handleEnable (id) {
      this.$confirm('确定要启用吗？', '提示', {
        type: 'warning'
      }).then(() => {
        SysUsersApi.enable([id]).then(() => {
          this.$message.success('启用成功')
          this.handleCurrentChange(1)
        })
      })
    },
    handleDisable (id) {
      this.$confirm('确定要禁用吗？', '提示', {
        type: 'warning'
      }).then(() => {
        SysUsersApi.disable([id]).then(() => {
          this.$message.success('禁用成功')
          this.handleCurrentChange(1)
        })
      })
    },
    handleOpenRoleDialog (userId) {
      this.roleDialogVisible = true
      this.$nextTick(() => {
        SysUsersApi.getUser(userId).then(data => {
          Object.assign(this.editForm, data)
        })
      })
    },
    handleSaveUserRole () {
      if (this.editForm.roleIds.length === 0) {
        this.$message.info('请选择角色')
        return
      }
      SysUsersApi.saveUserRole(this.editForm).then(() => {
        this.editRoleLoading = false
        this.roleDialogVisible = false
        this.$message.success('保存成功')
        this.handleCurrentChange(1)
      })
    },
    handleTabsClick () {
      this.rows = []
      this.handleCurrentChange(1)
    },
    handleOpenDealer () {
      this.dealerDialogShow = true
      this.$nextTick(() => {
        this.$refs.dealer.resetForm()
      })
    },
    handleDealerCreateSuccess () {
      this.dealerDialogShow = false
      this.$message.success('创建成功')
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style scoped lang="less">
.condition {
  position: relative;

  .right-tools {
    position: absolute;
    right: 10px;
    top: 0;
    display: flex;

    .right-tools-item {
      margin-left: 10px;
    }
  }
}
</style>
