<template>
  <div>
    <el-row>
      <el-col>
        <el-form :model="editForm" :rules="rules" ref="editForm" label-width="120px">
          <el-form-item label="帐户余额">
            <div>
              <span class="money">{{ currentAccount.balance }}</span> 元
            </div>
            <div class="tips">
              <span>开通金额：{{ dealerPrice }} 元</span>
              <span v-if="!balanceIsEnough">，帐户余额不足，请先充值</span>
            </div>
          </el-form-item>
          <el-form-item label="部门" prop="amsDeptId">
            <el-select v-model="editForm.amsDeptId">
              <el-option v-for="item in deptList" :key="item.id" :label="item.name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户名" prop="username">
            <el-input v-model="editForm.username" autocomplete="off" placeholder="登录系统的账号"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="editForm.password" type="password" autocomplete="new-password"
                      placeholder="登录系统的密码"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="editForm.mobile" autocomplete="on"></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="displayName">
            <el-input v-model="editForm.displayName"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCard">
            <el-input v-model="editForm.idCard"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="editForm.email"></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="roleIds">
            <el-checkbox-group v-model="editForm.roleIds">
              <el-checkbox v-for="item in roles" :label="item.id" name="roleIds" :key="item.id">
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSave" :loading="editLoading" :disabled="!balanceIsEnough">立即开通
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import { AccountDealerApi } from '@/api'
import Dictionary from '@/utils/dictionary'

const defaultForm = () => {
  return {
    username: '',
    password: '',
    displayName: '',
    mobile: '',
    email: '',
    amsDeptId: '',
    userType: Dictionary.amsUserType.dealer.code,
    roleIds: []
  }
}

export default {
  name: 'DealerCreate',
  props: {
    currentAccount: Object,
    roles: Array,
    deptList: Array
  },
  data () {
    return {
      editForm: defaultForm(),
      rules: {
        username: [
          {
            required: true,
            validator: function (rule, value, callback) {
              if (/^[a-zA-Z0-9]{1,16}$/.test(value) === false) {
                callback(new Error('用户名只能包含数字和字母,不能超过16位,不能包含特殊字符、中文'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ],
        amsDeptId: [
          {
            required: true,
            message: '请选择部门',
            trigger: 'change'
          }
        ],
        displayName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        idCard: [
          {
            required: true,
            message: '请输入身份证号',
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: '请输入邮箱地址',
            trigger: 'blur'
          }
        ],
        roleIds: [
          {
            type: 'array',
            required: true,
            message: '请至少选择一个角色',
            trigger: 'change'
          }
        ]
      },
      editLoading: false,
      dealerPrice: ''
    }
  },
  computed: {
    balanceIsEnough () {
      const balance = Number(this.currentAccount.balance) * 100
      const totalAmount = Number(this.dealerPrice) * 100
      return balance >= totalAmount
    }
  },
  methods: {
    handleRechargeVipCard () {
      this.$router.push('/account/recharge/vip/card')
    },
    handleSave () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.editLoading = true
          AccountDealerApi.createDealer(this.editForm).then(() => {
            this.editLoading = false
            this.$emit('success')
          }).catch(() => {
            this.editLoading = false
          })
        } else {
          return false
        }
      })
    },
    resetForm () {
      this.editForm = defaultForm()
    }
  },
  created () {
    AccountDealerApi.getDealerPrice().then(res => {
      this.dealerPrice = res
    })
  }
}
</script>

<style scoped lang="less">
.tips {
  color: #f95959;
}

.money {
  font-size: 20px;
  font-weight: bold;
}
</style>
